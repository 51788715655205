<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'modelosplanoEnsino' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Modelo de Plano de Ensino
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
              <e-label>Descrição:</e-label>
              <v-text-field
                outlined
                label="Descrição do Modelo"
                name="input-7-1"
                v-model="formPlano.descricao"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-card
          class="animate__animated animate__zoomIn row mb-5 ml-1 mr-1"
          style="animation-duration: 0.5s"
          v-for="sub of campos"
          :key="campos.indexOf(sub)"
        >
          <v-col class="pt-0 pb-0 mb-3 mt-3" cols="12">
            <h5>Campo {{ campos.indexOf(sub) + 1 }}</h5>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }">
              <v-text-field
                outlined
                label="Nome do Campo"
                name="input-7-1"
                v-model="sub.nome_campo"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
              <v-select
                :items="tiposCampos"
                :error-messages="errors"
                label="Selecione um Tipo Para o Campo"
                v-model="sub.tipo_campo"
                :item-text="(componente) => componente"
                :item-value="(componente) => componente"
                outlined
              ></v-select>
            </ValidationProvider>
          </v-col>

          <v-col v-if="sub.tipo_campo === 'Seleção'" class="pt-0 pb-0" cols="12">
            <!-- <ValidationProvider name="Opcoes" rules="required" v-slot="{ errors }"> -->
            <v-textarea
              label="Separe as opções com uma barra ex: Opcao 1 | Opcao 2 | Opcao 3  "
              v-model="sub.opcoes_campo"
              outlined
            ></v-textarea>
            <!-- </ValidationProvider> -->
            <v-switch v-model="sub.condicao_ativado" :label="`Adicionar Condição`"></v-switch>
            <div v-if="sub.condicao_ativado == true">
              <h3 class="mb-2">Condição para exibição desse campo</h3>
              <v-select
                :items="camposPadroes"
                label="De acordo com o campo"
                v-model="sub.condicao_campo"
                :item-text="(componente) => componente"
                :item-value="(componente) => componente"
                @change="getOpcoes(sub.condicao_campo)"
                outlined
              >
              </v-select>
            </div>
          </v-col>
        </v-card>

        <v-btn
          class="mb-5 mt-5"
          color="secondary"
          @click="adicionarCampo"
          :disabled="submittingForm"
        >
          Adicionar Campo
        </v-btn>

        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<style></style>

<script>
import { mapActions, mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formPlano() {
      if (!this.circuitos || !this.editing) return {};
      const { circuito_id } = this.$route.params;
      const circuito = this.circuitos.filter((cir) => cir.id === parseInt(circuito_id, 10)).shift();
      return { ...circuito };
    },
  },
  mounted() {
    this.loadTurmas();
  },
  data() {
    return {
      submittingForm: false,
      campos: [{}],
      series: [],
      tiposCampos: ["Texto", "Seleção"],
      camposPadroes: ["COMPONENTES CURRICULARES"],
      operadores: ["Maior que", "Menor que", "Igual a"],
      showComponentesCurriculares: false,
      opcoesComponentesCurriculares: [
        {
          text: "LÍNGUA PORTUGUESA",
          id: 1,
        },
        {
          text: "MATEMÁTICA",
          id: 2,
        },
        {
          text: "HISTÓRIA",
          id: 3,
        },
        {
          text: "Geografia",
          id: 4,
        },
        {
          text: "CIÊNCIAS",
          id: 5,
        },
        {
          text: "ARTE",
          id: 6,
        },
        {
          text: "EDUCAÇÃO FÍSICA",
          id: 7,
        },
        {
          text: "LÍNGUA INGLESA",
          id: 8,
        },
        {
          text: "ENSINO RELIGIOSO",
          id: 9,
        },
      ],
    };
  },
  methods: {
    getOpcoes(campo) {
      this.showComponentesCurriculares = false;
      switch (campo) {
        case "COMPONENTES CURRICULARES":
          this.showComponentesCurriculares = true;
          break;

        default:
          break;
      }
    },
    adicionarCampo() {
      const ultimoCampo = this.campos[this.campos.length - 1];
      this.campos.push({ ...ultimoCampo });
    },

    async loadTurmas() {
      try {
        const data = await this.$services.seriesService.syncAll();
        this.series = data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async submitForm() {
      this.submittingForm = true;
      this.campos.forEach((campo) => {
        if (campo.tipo_campo === "Seleção") {
          if (campo.condicao_ativado === true) {
            campo.condicao_campo = campo.condicao_campo;
            campo.condicao_operacao = campo.condicao_operacao;
            campo.condicao_campo_valor = campo.condicao_campo_valor;
          } else {
            campo.opcoes_campo = campo.opcoes_campo.split("|");
          }
        }
      });

      this.formPlano.estrutura = JSON.stringify(this.campos);

      try {
        if (this.editing) {
          // await this.$services.professoresService.atualizarProfessor(this.form);
          // this.$toast.success("Modelo Atualizada com sucesso");
        } else {
          await this.$services.modelosPlanoEnsinoService.criar(this.formPlano);
          this.$toast.success("Modelo criado com sucesso");
        }
        this.$router.push({ name: "modelosplanoEnsino" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
  },
};
</script>

<style></style>
