import municipios from "./constantes_grandes/municipios";

export const $constants = {
  tiposDeMediacaoDidaticoPedagogica: ["Presencial", "Semipresencial", "Educação a distância – EAD"],
  localDeFuncionamentoDiferenciadoDaTurma: [
    "Sala anexa",
    "Unidade de atendimento socioeducativo",
    "Unidade prisional",
    "A turma não está em local de funcionamento diferenciado",
  ],
  diasDaSemana: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
  tiposDeAtendimento: [
    "Escolarização",
    "Atendimento educacional especializado (AEE)",
    "Atividade complementar",
  ],
  modalidades: [
    "Ensino regular",
    "Educação especial - modalidade substitutiva",
    "Educação de jovens e adultos",
    "Educação profissional",
  ],

  situacoesMatricula: [
    "CURSANDO",
    // "APROVADO",
    "INATIVO",
    // "REPROVADO",
    "FALECIDO",
    "ABANDONO",
    "TRANSFERIDO",
    "CANCELADO",
    "TROCA DE TURMA",
    "TRANSFERIDO OUTROS",
    "RECEBIDA",
  ],
  // SE ADICIONAR MAIS ALGUMA CONDIÇÃO(SITUAÇÃO) PODE BLOQUEAR FUNCIONALIDADES EM OUTROS LOCAIS
  inultilidadeDeAcoesDaMatriculaParaSituacoesEspecificas: {
    TRANSFERIDO: true,
  },

  situacoesUsuarios: [
    {
      descricao: "ATIVO",
      cor: "success",
    },
    {
      descricao: "BLOQUEADO",
      cor: "error",
    },
  ],

  turnos: [
    {
      id: 1,
      descricao: "Manhã",
    },
    {
      id: 2,
      descricao: "Tarde",
    },
    {
      id: 3,
      descricao: "Noite",
    },
    {
      id: 4,
      descricao: "Integral",
    },
  ],

  tipos_de_horarios: [
    {
      id: 1,
      descricao: "Regular",
    },
    {
      id: 2,
      descricao: "Integral",
    },
    {
      id: 3,
      descricao: "Creche",
    },
    {
      id: 4,
      descricao: "Creche Integral",
    },
    {
      id: 5,
      descricao: "EJA",
    },
  ],

  situacoesMatriculaCondition: {
    CURSANDO: false,
    INATIVO: true,
    FALECIDO: true,
    ABANDONO: true,
    TRANSFERIDO: true,
    CANCELADO: true,
    TROCA_DE_TURMA: true,
    TRANSFERIDO_OUTROS: true,
  },

  vinculo: [
    {
      desc: "Efetivo",
      value: 0,
    },
    {
      desc: "Celetista",
      value: 1,
    },
    {
      desc: "Contrato",
      value: 2,
    },
  ],

  bimestres: [
    {
      desc: "1° bimestre",
      value: 0,
    },
    {
      desc: "2° bimestre",
      value: 1,
    },
    {
      desc: "3° bimestre",
      value: 2,
    },
    {
      desc: "4° bimestre",
      value: 3,
    },
  ],

  tipos_pesquisa_aluno: [
    {
      desc: "Pai",
      value: "filiacao2",
    },
    {
      desc: "Mãe",
      value: "filiacao1",
    },
    {
      desc: "Data de nasc",
      value: "data_nascimento",
    },
    {
      desc: "Sexo",
      value: "sexo_id",
    },
    {
      desc: "Cor/Raça",
      value: "cor_ou_raca",
    },
    {
      desc: "Nacionalidade",
      value: "naturalidade",
    },
    {
      desc: "País de nacionalidade",
      value: "nacionalidade",
    },
    {
      desc: "UF de nascimento",
      value: "estadualidade",
    },
  ],
  orgaosEmissores: [
    {
      id: 1,
      sigla: "SSP",
      descricao: "Secretaria de Segurança Pública",
    },
    {
      id: 2,
      sigla: "PM",
      descricao: "Polícia Militar",
    },
    {
      id: 3,
      sigla: "PC",
      descricao: "Polícia Civil",
    },
    {
      id: 4,
      sigla: "CNH",
      descricao: "Carteira Nacional de Habilitação",
    },
    {
      id: 5,
      sigla: "DIC",
      descricao: "Diretoria de Identificação Civil",
    },
    {
      id: 6,
      sigla: "CTPS",
      descricao: "Carteira de Trabaho e Previdência Social",
    },
    {
      id: 7,
      sigla: "FGTS",
      descricao: "Fundo de Garantia do Tempo de Serviço",
    },
    {
      id: 8,
      sigla: "IFP",
      descricao: "Instituto Félix Pacheco",
    },
    {
      id: 9,
      sigla: "IPF",
      descricao: "Instituto Pereira Faustino",
    },
    {
      id: 10,
      sigla: "IML",
      descricao: "Instituto Médico-Legal",
    },
    {
      id: 11,
      sigla: "MTE",
      descricao: "Ministério do Trabalho e Emprego",
    },
    {
      id: 12,
      sigla: "MMA",
      descricao: "Ministério da Marinha",
    },
    {
      id: 13,
      sigla: "MAE",
      descricao: "Ministério da Aeronáutica",
    },
    {
      id: 14,
      sigla: "MEX",
      descricao: "Ministério do Exército",
    },
    {
      id: 15,
      sigla: "POF",
      descricao: "Polícia Federal",
    },
    {
      id: 16,
      sigla: "POM",
      descricao: "Polícia Militar",
    },
    {
      id: 17,
      sigla: "SES",
      descricao: "Carteira de Estrangeiro",
    },
    {
      id: 18,
      sigla: "SJS",
      descricao: "Secretaria da Justiça e Segurança",
    },
    {
      id: 19,
      sigla: "SJTS",
      descricao: "Secretaria da Justiça do Trabalho e Segurança",
    },
    {
      id: 20,
      sigla: "ZZZ",
      descricao: "Outros (inclusive exterior)",
    },
  ],
  meses: [
    {
      descricao: "Janeiro",
      id: 1,
    },
    {
      descricao: "Fevereiro",
      id: 2,
    },
    {
      descricao: "Março",
      id: 3,
    },
    {
      descricao: "Abril",
      id: 4,
    },
    {
      descricao: "Maio",
      id: 5,
    },
    {
      descricao: "Junho",
      id: 6,
    },
    {
      descricao: "Julho",
      id: 7,
    },
    {
      descricao: "Agosto",
      id: 8,
    },
    {
      descricao: "Setembro",
      id: 9,
    },
    {
      descricao: "Outubro",
      id: 10,
    },
    {
      descricao: "Novembro",
      id: 11,
    },
    {
      descricao: "Dezembro",
      id: 12,
    },
  ],

  planos: [
    {
      descricao: "Bimestral",
      route: "gestoesDeAulas.planoBimestralCreate",
    },
    {
      descricao: "Bimestral infantil",
      route: "gestoesDeAulas.planoBimestralCreate",
    },
  ],

  planosForm: [
    {
      descricao: "Unidades tématicas / práticas de linguagem",
      form: "tematica",
      abreviacao: "UNID TEM/PRAT. LING",
    },
    {
      descricao: "OBJETOS DO CONHECIMENTO",
      form: "objeto_conhecimento",
      abreviacao: "OBJ CONH.",
    },
    {
      descricao: "HABILIDADES",
      form: "habilidades",
      abreviacao: "HAB",
    },
    {
      descricao: "ODS",
      form: "ods",
      abreviacao: "ODS",
    },
    {
      descricao: "METODOLOGIA",
      form: "metodologia",
      abreviacao: "METOD",
    },
    {
      descricao: "RECURSOS DIDÁTICOS",
      form: "recursos_didaticos",
      abreviacao: "REC DIDAT",
    },
    {
      descricao: "AVALIAÇÃO DA APRENDIZAGEM",
      form: "avaliacao_aprendizagem",
      abreviacao: "AVAL. APREND",
    },
    {
      descricao: "REFERÊNCIAS",
      form: "referencias",
      abreviacao: "REF.",
    },
  ],

  planosBimestraisForm: [
    {
      descricao: "UNIDADE TEMÁTICA",
      form: "unidades_tematicas",
    },
    {
      descricao: "OBJETO DE CONHECIMENTO",
      form: "objeto_conhecimento",
    },
    {
      descricao: "HABILIDADES (código alfanumérico BNCC)",
      form: "habilidades",
    },
    {
      descricao: "ABORDAGEM PEDAGÓGICA",
      form: "abordagem_pedagogica",
    },
    {
      descricao: "RECURSOS DIDÁTICOS",
      form: "recursos_didaticos",
    },
    {
      descricao: "PROCESSOS AVALIATIVOS",
      form: "processos_avaliativos",
    },
  ],

  planosBimestraisInfantil: [
    {
      descricao: "ATIVIDADE/POSSIBILIDADE DE EXPERIÊNCIA",
      form: "atividade_de_experiencia",
    },
    {
      descricao: "DIREITOS DE APREDIZAGEM",
      form: "direito_aprendizagem",
    },
    {
      descricao: "CAMPOS DE EXPERIÊNCIA",
      form: "campos_de_experiencia",
    },
    {
      descricao: "CÓDIGO ALFANUMERICO COM OBJETIVO",
      form: "codigo_alfanumerico",
    },
    {
      descricao: "RECURSOS",
      form: "recursos",
    },
    {
      descricao: "AVALIAÇÃO",
      form: "avaliacao",
    },
    {
      descricao: "REFERÊNCIA",
      form: "referencia",
    },
  ],

  sistemaDeNotas: ["Numerica", "Relatório Infantil", "Relatório Individual"],
  atividadesComplementaresList: [
    {
      codigo: 11002,
      descricao: "Canto coral",
    },
    {
      codigo: 11006,
      descricao: "Banda",
    },
    {
      codigo: 11011,
      descricao: "Iniciação Musical",
    },
    {
      codigo: 12003,
      descricao: "Desenho",
    },
    {
      codigo: 12004,
      descricao: "Escultura e Cerâmica",
    },
    {
      codigo: 12005,
      descricao: "Grafite",
    },
    {
      codigo: 12007,
      descricao: "Pintura",
    },
    {
      codigo: 13001,
      descricao: "Cineclube",
    },
    {
      codigo: 14001,
      descricao: "Teatro",
    },
    {
      codigo: 14002,
      descricao: "Danças",
    },
    {
      codigo: 14004,
      descricao: "Práticas Circenses",
    },
    {
      codigo: 15001,
      descricao: "Capoeira",
    },
    {
      codigo: 15002,
      descricao: "Artesanato",
    },
    {
      codigo: 15003,
      descricao: "Brinquedos",
    },
    {
      codigo: 15004,
      descricao: "Contos",
    },
    {
      codigo: 16001,
      descricao: "Educação Patrimonial",
    },
    {
      codigo: 17004,
      descricao: "Leitura",
    },
    {
      codigo: 17002,
      descricao: "Línguas Estrangeiras",
    },
    {
      codigo: 19999,
      descricao: "Outra categoria de Cultura, Artes e Educação Patrimonial",
    },
    {
      codigo: 21001,
      descricao: "Recreação (Brinquedoteca e Jogos)",
    },
    {
      codigo: 22007,
      descricao: "Yoga",
    },
    {
      codigo: 22009,
      descricao: "Tênis de campo",
    },
    {
      codigo: 22011,
      descricao: "Atletismo",
    },
    {
      codigo: 22012,
      descricao: "Badminton",
    },
    {
      codigo: 22014,
      descricao: "Basquete",
    },
    {
      codigo: 22015,
      descricao: "Ciclismo",
    },
    {
      codigo: 22018,
      descricao: "Futebol",
    },
    {
      codigo: 22019,
      descricao: "Futsal",
    },
    {
      codigo: 22020,
      descricao: "Ginástica(rítmica, artística, acrobática)",
    },
    {
      codigo: 22021,
      descricao: "Handebol",
    },
    {
      codigo: 22022,
      descricao: "Judô",
    },
    {
      codigo: 22023,
      descricao: "Karatê",
    },
    {
      codigo: 22024,
      descricao: "Luta Olímpica",
    },
    {
      codigo: 22025,
      descricao: "Natação",
    },
    {
      codigo: 22026,
      descricao: "Taekwondo",
    },
    {
      codigo: 22027,
      descricao: "Tênis de Mesa",
    },
    {
      codigo: 22028,
      descricao: "Voleibol",
    },
    {
      codigo: 22029,
      descricao: "Vôlei de Praia",
    },
    {
      codigo: 22030,
      descricao: "Xadrez Tradicional/xadrez virtual",
    },
    {
      codigo: 29999,
      descricao: "Outra categoria de Esporte e Lazer",
    },
    {
      codigo: 22025,
      descricao: "Natação",
    },
    {
      codigo: 31002,
      descricao: "Português",
    },
    {
      codigo: 31001,
      descricao: "Matemática",
    },
    {
      codigo: 39999,
      descricao: "Outra categoria de Acompanhamento Pedagógico",
    },
    {
      codigo: 41007,
      descricao: "Educação em Direitos Humanos",
    },
    {
      codigo: 71007,
      descricao: "Promoção da Saúde",
    },
    {
      codigo: 10103,
      descricao: "Iniciação Científica",
    },
    {
      codigo: 13301,
      descricao: "Educação Ambiental e Desenvolvimento Sustentável",
    },
    {
      codigo: 13108,
      descricao: "Economia Solidária e Criativa/Educação Econômica (Educação Financeira e Fiscal)",
    },
    {
      codigo: 14101,
      descricao: "Fotografia",
    },
    {
      codigo: 14102,
      descricao: "História em Quadrinhos",
    },
    {
      codigo: 14103,
      descricao: "Jornal Escolar",
    },
    {
      codigo: 14104,
      descricao: "Rádio Escolar",
    },
    {
      codigo: 22025,
      descricao: "Vídeo",
    },
    {
      codigo: 14201,
      descricao: "Robótica Educacional",
    },
    {
      codigo: 14202,
      descricao: "Tecnologias Educacionais",
    },
    {
      codigo: 14203,
      descricao: "Ambientes de Redes Sociais",
    },
    {
      codigo: 14999,
      descricao: "Outra Categoria de Comunicação, Uso de Mídias e Cultura Digital e Tecnológica",
    },
    {
      codigo: 15101,
      descricao: "Memória e História das Comunidades Tradicionais",
    },
  ],
  sexoList: [
    {
      id: 1,
      descricao: "Masculino",
    },
    {
      id: 2,
      descricao: "Feminino",
    },
  ],
  corOuRacaList: ["Branca", "Preta", "Parda", "Amarela", "Indígena", "Não declarada"],
  nacionalidadeList: [
    "Brasileira",
    "Brasileira – nascido no exterior ou naturalizado",
    "Estrangeira",
  ],
  rolesQuePodemVerGraficos: [1, 6, 12],
  estadosList: [
    { codigo: "AC", descricao: "Acre" },
    { codigo: "AL", descricao: "Alagoas" },
    { codigo: "AP", descricao: "Amapá" },
    { codigo: "AM", descricao: "Amazonas" },
    { codigo: "BA", descricao: "Bahia" },
    { codigo: "CE", descricao: "Ceará" },
    { codigo: "DF", descricao: "Distrito Federal" },
    { codigo: "ES", descricao: "Espírito Santo" },
    { codigo: "GO", descricao: "Goías" },
    { codigo: "MA", descricao: "Maranhão" },
    { codigo: "MT", descricao: "Mato Grosso" },
    { codigo: "MS", descricao: "Mato Grosso do Sul" },
    { codigo: "MG", descricao: "Minas Gerais" },
    { codigo: "PA", descricao: "Pará" },
    { codigo: "PB", descricao: "Paraíba" },
    { codigo: "PR", descricao: "Paraná" },
    { codigo: "PE", descricao: "Pernambuco" },
    { codigo: "PI", descricao: "Piauí" },
    { codigo: "RJ", descricao: "Rio de Janeiro" },
    { codigo: "RN", descricao: "Rio Grande do Norte" },
    { codigo: "RS", descricao: "Rio Grande do Sul" },
    { codigo: "RO", descricao: "Rondônia" },
    { codigo: "RR", descricao: "Roraíma" },
    { codigo: "SC", descricao: "Santa Catarina" },
    { codigo: "SP", descricao: "São Paulo" },
    { codigo: "SE", descricao: "Sergipe" },
    { codigo: "TO", descricao: "Tocantins" },
  ],
  municipios,
  tipoDeDeficienciaList: [
    "Baixa visão",
    "Cegueira",
    "Deficiência auditiva",
    "Deficiência física",
    "Deficiência intelectual",
    "Deficiência Múltipla",
    "Epilepsia",
    "Síndrome de Down",
    "Surdez",
    "Surdocegueira",
    "Transtorno do espectro autista",
  ],
  objetivosDesenvolvimentoSustentavelList: [
    "ODS 1 - Erradicação da pobreza",
    "ODS 2 - Fome zero e agricultura sustentável",
    "ODS 3 - Saúde e bem-estar",
    "ODS 4 - Educação de qualidade",
    "ODS 5 - Igualdade de gênero",
    "ODS 6 - Água potável e saneamento",
    "ODS 7 - Energia limpa e acessível",
    "ODS 8 - Trabalho decente e crescimento econômico",
    "ODS 9 - Indústria, inovação e infraestrutura",
    "ODS 10 - Redução das desigualdades",
    "ODS 11 - Cidades e comunidades sustentáveis",
    "ODS 12 - Consumo e produção responsáveis",
    "ODS 13 - Ação contra a mudança global do clima",
    "ODS 14 - Vida na água",
    "ODS 15 - Vida terrestre",
    "ODS 16 - Paz, justiça e instituições eficazes",
    "ODS 17 - Parcerias e meios de implementação",
  ],
  tipoDeTranstornoDoEspectroAutistaList: ["Transtorno do espectro autista"],
  tipoDeAltasHabilidadesList: ["Altas habilidades/Superdotação"],
  recursosSaebList: [
    "Auxílio ledor",
    "Auxílio transcrição",
    "Guia-intérprete",
    "Tradutor-intérprete de Libras",
    "Leitura labial",
    "Prova ampliada (Fonte 18)",
    "Prova superampliada (Fonte 24)",
    "CD com áudio para deficiente visual",
    "Prova de Língua Portuguesa como Segunda Língua para surdos e deficientes auditivos",
    "Prova em Video Libras",
    "Material didático e prova em Braille",
    "Nenhum",
  ],
  zonaResidenciaList: ["Urbana", "Rural"],
  localizacaoDiferenciadaDeResidenciaList: [
    "Não está em área de localização diferenciada",
    "Área onde se localiza comunidade remanescente de quilombos",
    "Terra indígena",
    "Área de assentamento",
  ],
  rolesParaProfessores: [
    {
      id: "2",
      name: "professor(a)",
    },
    {
      id: "5",
      name: "coordenador",
    },
    {
      id: "7",
      name: "direção",
    },
    {
      id: "8",
      name: "supervisor",
    },
  ],
  rolesQueNaoDevemVerMenuMatriculas: [
    {
      id: "2",
      name: "professor(a)",
    },
    {
      id: "3",
      name: "aluno(a)",
    },
    {
      id: "11",
      name: "colaborador(a)",
    },
  ],

  rolesParaGestores: [
    {
      id: "1",
      name: "admin",
    },
    {
      id: "4",
      name: "secretario",
    },
    {
      id: "6",
      name: "super-admin",
    },
    {
      id: "9",
      name: "aux-admin",
    },
    {
      id: "12",
      name: "técnico-semed",
    },
  ],

  tiposDeAulas: [
    "Aula Remota",
    "Aula Normal",
    "Reposição",
    "Aula Extra",
    "Substituição",
    "Aula Antecipada",
    "Atividade Extra-classe",
    "Recuperação",
  ],
  situacoesAulas: [
    {
      descricao: "Aula confirmada",
      cor: "success",
    },
    {
      descricao: "Aguardando confirmação",
      cor: "blue-grey lighten-4",
    },
    {
      descricao: "Aula rejeitada por falta",
      cor: "error",
    },
    {
      descricao: "Aula inválida",
      cor: "brown lighten-3",
    },
    {
      descricao: "Aula em conflito",
      cor: "warning",
    },
  ],
  situacoesAulasTabs: [
    {
      descricao: "Confirmadas",
      cor: "green lighten-4",
      tipo: "Aula confirmada",
    },
    {
      descricao: "Aguardando",
      cor: "blue-grey lighten-4",
      tipo: "Aguardando confirmação",
    },
    {
      descricao: "Rejeitadas",
      cor: "red lighten-4",
      tipo: "Aula rejeitada por falta",
    },
    {
      descricao: "Inválidas",
      cor: "brown lighten-5",
      tipo: "Aula inválida",
    },
    {
      descricao: "Conflitadas",
      cor: "amber lighten-4",
      tipo: "Aula em conflito",
    },
  ],
  situacoesAulasCor: {
    "Aula confirmada": "green lighten-2",
    "Aguardando confirmação": "blue-grey lighten-2",
    "Aula rejeitada por falta": "red lighten-2",
    "Aula inválida": "brown lighten-3",
    "Aula em conflito": "amber lighten-2",
  },
  situacoesAulasCorLight: {
    "Aula confirmada": "green lighten-4",
    "Aguardando confirmação": "blue-grey lighten-4",
    "Aula rejeitada por falta": "red lighten-4",
    "Aula inválida": "brown lighten-5",
    "Aula em conflito": "amber lighten-4",
  },
  subEtapaTipoCampos: [
    {
      descricao: "Valor (Inserir) - Número",
      slug: "valor",
    },
    {
      descricao: "Valor (Inserir) - Texto",
      slug: "valor_texto",
    },
    {
      descricao: "Resultado em número",
      slug: "resultado_em_lote",
    },
    {
      descricao: "Resultado em Texto",
      slug: "resultado_em_texto",
    },
  ],
  situacaoAutorizacao: ["PENDENTE", "RECUSADO", "APROVADO"],
  cores: [
    {
      descricao: "Preto",
      codigo: "#000",
    },
    {
      descricao: "Vermelho",
      codigo: "red",
    },
    {
      descricao: "Azul",
      codigo: "blue",
    },
    {
      descricao: "Amarelo",
      codigo: "yellow",
    },
    {
      descricao: "Verde",
      codigo: "green",
    },
  ],
  anos: ["1º ANO", "2º ANO", "3º ANO", "4º ANO", "5º ANO", "6º ANO", "7º ANO", "8º ANO", "9º ANO"],
  areaConhecimento: [
    "Linguagens",
    "Matemática",
    "Ciências da Natureza",
    "Ciências Humanas",
    "Ensino Religioso",
  ],
  componentesCurriculares: [
    "Língua Portuguesa",
    "Arte",
    "Educação Física",
    "Língua Inglesa",
    "Matemática",
    "Ciências da Natureza",
    "História",
    "Geografia",
    "Ensino Religioso",
  ],
  avaliacoes: [
    {
      descricao: "1° Avaliação",
      id: 1,
    },
    {
      descricao: "2° Avaliação",
      id: 2,
    },
    {
      descricao: "3° Avaliação",
      id: 3,
    },
    {
      descricao: "4° Avaliação",
      id: 4,
    },
    {
      descricao: "5° Avaliação",
      id: 5,
    },
    {
      descricao: "6° Avaliação",
      id: 6,
    },
    {
      descricao: "7° Avaliação",
      id: 7,
    },
    {
      descricao: "8° Avaliação",
      id: 8,
    },
  ],
  referencia_encontros: [
    "1º Encontro",
    "2º Encontro",
    "3º Encontro",
    "4º Encontro",
    "5º Encontro",
    "6º Encontro",
    "7º Encontro",
    "8º Encontro",
    "9º Encontro",
    "10º Encontro",
    "11º Encontro",
    "12º Encontro",
    "13º Encontro",
    "14º Encontro",
    "15º Encontro",
    "16º Encontro",
    "17º Encontro",
    "18º Encontro",
    "19º Encontro",
    "20º Encontro",
  ],
  componente_curriculares: [
    {
      text: "Eu, o outro e o nós, Corpo, gestos e movimentos, Traços, sons, cores e formas, Escuta, fala, pensamento e imaginação e Espaço, tempo, quantidades, relações e transformações",
      slug: 0,
    },
    {
      text: "Língua Portuguesa",
      slug: 1,
    },
    {
      text: "Matemática",
      slug: 2,
    },
    {
      text: "Geografia",
      slug: 3,
    },
    {
      text: "História",
      slug: 4,
    },
    {
      text: "Ciências da Natureza",
      slug: 5,
    },
    {
      text: "Língua Inglesa",
      slug: 6,
    },
    {
      text: "Educação Física",
      slug: 7,
    },
    {
      text: "Ensino Religioso",
      slug: 8,
    },
    {
      text: "Educação de Jovens E Adultos",
      slug: 9,
    },
    {
      text: "Educação Especial e Inclusiva e Equipe Multidisciplinar",
      slug: 10,
    },
    {
      text: "Todos",
      slug: 11,
    },
  ],
  campos_de_experiencia: [
    {
      id: 0,
      text: "O Eu o Outro e o Nós",
      array: [
        "O Respeita e expressa sentimentos e emoções,atuando com progressiva autonomia emocional.",
        "Atua em grupo e demonstra interesse em construir novas relações, respeitando a diversidade e solidarizando-se com os outros.",
        "Age com progressiva autonomia em relação ao próprio corpo e ao espaço que ocupa, apresentando independência e iniciativa.",
        "Conhece, respeita e cumpre regras de convívio social, manifestando respeito pelo outro ao lidar com conflitos.",
        "Compartilha objetos e espaços.",
        "Segue os combinados elaborados pelo grupo",
      ],
    },
    {
      id: 1,
      text: "Corpo, gesto, e movimento",
      array: [
        "Reconhece a importância de ações e situações do cotidiano que contribuem para o cuidado de sua saúde e a manutenção de ambientes saudáveis.",
        "Apresenta autonomia nas práticas de higiene, alimentação, vestir-se e no cuidado com seu bem-estar, valorizando o próprio corpo.",
        "Utiliza o corpo intencionalmente (com criatividade, controle e adequação) como instrumento de interação com o outro e com o meio.",
        "Coordena suas habilidades psicomotoras finas.",
        "Respeita a trajetória correta ao escrever seu nome e palavras conhecidas.",
        "Segura corretamente o lápis ao escrever ou pintar.",
        "Realiza circuitos com obstáculos.",
        "Respeita a direção esquerda – direita na escrita.",
        "Recorta com precisão do movimento e manipular a outra mão corretamente.",
        "Faz e desfaz nós e laços",
        "Valoriza a limpeza e a aparência pessoal e dos ambientes.",
      ],
    },
    {
      id: 2,
      text: "Traços, sons, cores e formas",
      array: [
        "Discrimina os diferentes tipos de sons e ritmos e interagir com a música, percebendo-a como forma de expressão individual e coletiva.",
        "Reconhece as artes visuais como meio de comunicação, expressão e construção do conhecimento.",
        "Relaciona-se com o outro empregando gestos, palavras, brincadeiras, jogos, imitações, observações e expressão corporal",
        "Recria a partir de imagens, figuras e objetos, usando materiais simples e ensaiando algumas produções expressivas.",
        "Aprecia e participa de apresentações, dramatizações e recitação de falas numa apresentação.",
        "Identifica características das formas geométricas com suas cores e composições",
      ],
    },
    {
      id: 3,
      text: "Escuta, fala, pensamento e imaginação",
      array: [
        "Levanta hipóteses sobre gêneros textuais veiculados em portadores conhecidos, recorrendo a estratégias de observação gráfica e/ou de leitura.",
        "Expressa ideias, desejos e sentimentos em distintas situações de interação, por diferentes meios.",
        "Levanta hipóteses em relação à linguagem escrita, realizando registros de palavras e textos, por meio de escrita espontânea.",
        "Ouve, compreende, conta, reconta e cria narrativas.",
        "Nomeia as letras do alfabeto em diferentes fontes",
        "Diferencia letras de números e símbolos.",
        "Escreve o próprio nome completo ou pelo menos o prenome",
        "Identifica, cenários, personagens e a estrutura da história.",
        "Participa oralmente da produção de texto coletivos ou recontos (professora como escriba)",
        "Interpreta oralmente texto lidos pela professora",
        "Acompanha a leitura realizada pela professora",
        "Ler palavras canônicas",
        "Ler palavras globalmente",
        "Identifica o espaçamento entre as palavras na segmentação da escrita.",
      ],
    },
    {
      id: 4,
      text: "Espaços, tempos, quantidade, relações e transformações.",
      array: [
        "Estabelece relações de comparação entre objetos, observando suas propriedades.",
        "Interage com o meio ambiente e com fenômenos naturais ou artificiais, demonstrando atitudes de investigação, respeito e preservação.",
        "Utiliza vocabulário relativo às noções de grandeza (maior, menor, igual etc.), espaço (dentro e fora) e medidas (comprido, curto, grosso, fino) como meio de comunicação de suas experiências.",
        "Identifica e registra quantidades por meio de diferentes formas de representação (contagens,desenhos, símbolos, escrita de números)",
        "Interpreta gráficos simples",
        "Organiza gráficos simples com a ajuda do professor.",
        "Classifica objetos e figuras de acordo com suas semelhanças e diferenças.",
        "Relata fatos importantes sobre seu nascimento e desenvolvimento, a história dos seus familiares e da sua comunidade",
        "Relaciona números às suas respectivas quantidades os números até 20",
        "Representa graficamente a sequência numérica até 50",
        "Identifica o antes, o depois e o entre em uma sequência numérica até o número 20. (definir até que número a criança consegue realizar)",
        "Seria e percebe as ordens de grandezas (tamanho, espessura, intensidade da cor, etc.) entre gravuras e objetos com 9 elementos.",
        "Organiza uma sequência com dois atributos, cor e forma.",
        "Demonstra noções adição utilizando representação numérica cuja soma seja até 9.",
        "Demonstra noções de subtração utilizando representação numérica com minuendo até 9.",
        "Compreende e organiza dados em uma tabela",
      ],
    },
  ],
  optionsInformativo: [
    { title: "Restringir a um grupo" },
    { title: "Definir data para mostrar" },
    { title: "Definir data para encerrar" },
  ],
};

export default (vue) => {
  vue.prototype.$constants = $constants;
};
