<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Planos de Ensino</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between pa-0">
          <v-btn color="primary" dark @click="criarModelo"> Criar Plano </v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="modelos"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="plano.estaDeletado ? ['deleted'] : []"
                v-for="plano of items"
                :key="plano.id"
              >
                <td>{{ plano.id }}</td>
                <td>{{ plano.titulo }}</td>
                <!-- <td>{{ plano.contexto }}</td> -->
                <td>
                  <!-- <v-tooltip v-if="!plano.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarPlano(plano)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar plano</span>
                  </v-tooltip> -->
                  <v-tooltip v-if="!plano.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarPlano(plano)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar plano</span>
                  </v-tooltip>
                  <v-tooltip v-if="plano.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarPlano(plano)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar plano</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="350px">
          <v-card>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
                <v-card-title>
                  <span class="text-h5">Selecione um Plano</span>
                </v-card-title>
                <v-card-text>
                  <ValidationProvider name="Plano Ensino" rules="required" v-slot="{ errors }">
                    <e-autocomplete
                      :items="anos"
                      :return-object="false"
                      :item-text="(plano) => plano.descricao"
                      :item-value="(plano) => plano.id"
                      :error-messages="errors"
                      v-model="anoId"
                      @change="changeAno(anoId)"
                      label="Selecione uma ano"
                      dense
                      solo
                    />
                  </ValidationProvider>
                  <ValidationProvider name="Plano Ensino" rules="required" v-slot="{ errors }">
                    <e-autocomplete
                      v-show="selectPlanos"
                      :items="planosDisponiveis"
                      :return-object="false"
                      :item-text="(plano) => plano.descricao"
                      :item-value="(plano) => plano.id"
                      :error-messages="errors"
                      v-model="plano_id"
                      label="Selecione um plano"
                      dense
                      solo
                    />
                  </ValidationProvider>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click="dialog = false"> Cancelar </v-btn>
                  <v-btn
                    color="green"
                    text
                    :disabled="submittingForm"
                    :loading="submittingForm"
                    type="submit"
                  >
                    Importar
                  </v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  data() {
    return {
      modelos: [],
      planosList: [],
      planosListGeral: [],
      plano_id: null,
      submittingForm: false,
      planos: [
        {
          id: 19,
          descricao: "plano 1",
        },
      ],
      anoId: null,
      dialog: false,
      dataTableFilter,
      search: "",
      planosDisponiveis: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          // { text: "Código", value: "codigo" },
          { text: "Nome", value: "descricao" },
          // { text: "Contexto", value: "contexto" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
      anos: [],
      selectPlanos: false,
    };
  },
  mounted() {
    this.loadModelos();
  },
  watch: {
    carregandoPlanos(value) {
      if (value && this.planosList.length === 0) {
        this.$loaderService.open("Carregando Planos");
      } else {
        this.$loaderService.close();
      }
    },
  },
  methods: {
    async deletarPlano(plano) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar este plano?",
        confirmationCode: plano.id,
        confirmationMessage: `Por favor, digite <strong>${plano.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando o plano");
              try {
                await this.$services.planosEnsinoService.deletar(plano);
                this.loadModelos();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },

    async loadModelos() {
      try {
        const data = await this.$services.planosEnsinoService.syncAll();
        this.modelos = data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    criarModelo() {
      this.$router.push({ name: "planosEnsino.create" });
    },

    editarPlano(plano) {
      this.$router.push({
        name: "planosEnsino.edit",
        params: { editing: true, plano_id: plano.id },
      });
    },
  },
};
</script>

<style></style>
