<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.go(-1)" :disabled="false">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Editar Relatório de Acompanhamento do Aluno
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="turma && aluno">
          <v-card-title>Relatório</v-card-title>
          <v-card-text>
            <h4>Turma: {{ turma.descricao }}</h4>
            <h4>Aluno: {{ aluno.nomecompleto }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Professores (diários)</v-card-title>
          <v-card-text>
            <div v-for="diario in gestao_de_aulas" :key="diario.id">
              Professor(a): {{ diario.professor.nome }} | Componentes Curriculares:
              {{ diario.disciplina.descricao }}
            </div>
          </v-card-text>
          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn icon @click="show = !show">
              <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
            </v-btn>
            <v-btn class="caption ml-2" @click="show = !show" text
              >Legendas para auxiliar no lançamento do sistema BNCC</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-card-text>
                <v-chip v-for="(legenda, i) in legendas" :key="i" class="ma-2" small>
                  <strong>{{ i }}</strong
                  >&nbsp;-&nbsp;{{ legenda }}
                </v-chip>
                <v-divider></v-divider>
                <v-chip v-for="(style, i) in styleClass" :key="i" class="ma-2" small :color="style">
                  <strong>{{ i }}</strong
                  >&nbsp;-&nbsp;lançamento(s)
                </v-chip>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <div v-for="aluno of alunos" :key="aluno.id">
      <v-card class="mt-3">
        <div class="mt-3">
          <h3 class="pa-3">{{ aluno.aluno.nomecompleto }}</h3>
        </div>
        <template>
          <v-tabs v-model="tab_atual" background-color="primary" dark>
            <v-tab v-for="tab of tabs" :key="tabs.indexOf(tab)">{{ tab.descricao }}</v-tab>
          </v-tabs>

          <v-card-text>
            <v-simple-table class="elevation-1" v-show="tab_atual == 0">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td colspan="5" class="text-h6">
                      <ul>
                        <li>
                          {{ $constants.campos_de_experiencia[0].text }}

                          <v-select
                            @change="updateCampo(0, aluno.aluno.id)"
                            :items="$constants.campos_de_experiencia[0].array"
                            :item-text="(dado) => dado"
                            :item-value="(dado) => dado"
                            multiple
                            label="Selecione"
                            v-model="campo_0_select_aluno[aluno.aluno.id]"
                            outlined
                          >
                          </v-select>
                        </li>
                        <li>
                          {{ $constants.campos_de_experiencia[1].text }}
                          <v-select
                            @change="updateCampo(1, aluno.aluno.id)"
                            :items="$constants.campos_de_experiencia[1].array"
                            :item-text="(dado) => dado"
                            :item-value="(dado) => dado"
                            multiple
                            label="Selecione"
                            v-model="campo_1_select_aluno[aluno.aluno.id]"
                            outlined
                          >
                          </v-select>
                        </li>
                        <li>
                          {{ $constants.campos_de_experiencia[2].text }}
                          <v-select
                            @change="updateCampo(2, aluno.aluno.id)"
                            :items="$constants.campos_de_experiencia[2].array"
                            :item-text="(dado) => dado"
                            :item-value="(dado) => dado"
                            multiple
                            label="Selecione"
                            v-model="campo_2_select_aluno[aluno.aluno.id]"
                            outlined
                          >
                          </v-select>
                        </li>
                        <li>
                          {{ $constants.campos_de_experiencia[3].text }}
                          <v-select
                            @change="updateCampo(3, aluno.aluno.id)"
                            :items="$constants.campos_de_experiencia[3].array"
                            :item-text="(dado) => dado"
                            :item-value="(dado) => dado"
                            multiple
                            label="Selecione"
                            v-model="campo_3_select_aluno[aluno.aluno.id]"
                            outlined
                          >
                          </v-select>
                        </li>
                        <li>
                          {{ $constants.campos_de_experiencia[4].text }}
                          <v-select
                            @change="updateCampo(4, aluno.aluno.id)"
                            :items="$constants.campos_de_experiencia[4].array"
                            :item-text="(dado) => dado"
                            :item-value="(dado) => dado"
                            multiple
                            label="Selecione"
                            v-model="campo_4_select_aluno[aluno.aluno.id]"
                            outlined
                          >
                          </v-select>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table class="elevation-1" v-show="tab_atual == 1">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td colspan="5" class="text-h6">
                      <ul>
                        <li>
                          {{ $constants.campos_de_experiencia[0].text }}
                          <v-textarea v-model="campo_0_texto_aluno[aluno.aluno.id]">
                            <template v-slot:label>
                              <div>
                                Conquistas e recomendações a respeito da criança de forma
                                contextualizada <small>(opcional)</small>
                              </div>
                            </template>
                          </v-textarea>
                        </li>
                        <li>
                          {{ $constants.campos_de_experiencia[1].text }}
                          <v-textarea v-model="campo_1_texto_aluno[aluno.aluno.id]">
                            <template v-slot:label>
                              <div>
                                Conquistas e recomendações a respeito da criança de forma
                                contextualizada <small>(opcional)</small>
                              </div>
                            </template>
                          </v-textarea>
                        </li>
                        <li>
                          {{ $constants.campos_de_experiencia[2].text }}
                          <v-textarea v-model="campo_2_texto_aluno[aluno.aluno.id]">
                            <template v-slot:label>
                              <div>
                                Conquistas e recomendações a respeito da criança de forma
                                contextualizada <small>(opcional)</small>
                              </div>
                            </template>
                          </v-textarea>
                        </li>
                        <li>
                          {{ $constants.campos_de_experiencia[3].text }}
                          <v-textarea v-model="campo_3_texto_aluno[aluno.aluno.id]">
                            <template v-slot:label>
                              <div>
                                Conquistas e recomendações a respeito da criança de forma
                                contextualizada <small>(opcional)</small>
                              </div>
                            </template>
                          </v-textarea>
                        </li>
                        <li>
                          {{ $constants.campos_de_experiencia[4].text }}
                          <v-textarea v-model="campo_4_texto_aluno[aluno.aluno.id]">
                            <template v-slot:label>
                              <div>
                                Conquistas e recomendações a respeito da criança de forma
                                contextualizada <small>(opcional)</small>
                              </div>
                            </template>
                          </v-textarea>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </template>
      </v-card>
    </div>
  </main-template>
</template>

<script>
export default {
  watch: {
    campo_0_texto_aluno(data) {
      this.updateTexto(0);
    },
    campo_1_texto_aluno(data) {
      this.updateTexto(1);
    },
    campo_2_texto_aluno(data) {
      this.updateTexto(2);
    },
    campo_3_texto_aluno(data) {
      this.updateTexto(3);
    },
    campo_4_texto_aluno(data) {
      this.updateTexto(4);
    },
  },
  data() {
    return {
      campo_0_select_aluno: [],
      campo_1_select_aluno: [],
      campo_2_select_aluno: [],
      campo_3_select_aluno: [],
      campo_4_select_aluno: [],
      campo_0_texto_aluno: [],
      campo_1_texto_aluno: [],
      campo_2_texto_aluno: [],
      campo_3_texto_aluno: [],
      campo_4_texto_aluno: [],
      alunos: [],
      legendas: null,
      opcoes: null,
      sistemaBNCC: null,
      notas: null,
      aluno: null,
      matricula: null,
      turma: null,
      gestao_de_aulas: null,
      promiseCount: 0,
      tab_atual: 0,
      resumos: {},
      styleClass: [
        "red lighten-4",
        "yellow accent-1",
        "orange accent-1",
        "lime lighten-3",
        "light-green lighten-1",
      ],
      show: false,
      tabs: [
        {
          id: 0,
          descricao: "SÍNTESE DE APRENDIZAGENS",
        },
        {
          id: 1,
          descricao: "CONQUISTAS E RECOMENDAÇÕES ",
        },
      ],
    };
  },
  created() {
    this.loadBasicData();
  },
  methods: {
    changeColor(obj) {
      let countClass = 0;
      if (this.notas[obj.id].nota_b1) {
        countClass += 1;
      }
      if (this.notas[obj.id].nota_b2) {
        countClass += 1;
      }
      if (this.notas[obj.id].nota_b3) {
        countClass += 1;
      }
      if (this.notas[obj.id].nota_b4) {
        countClass += 1;
      }
      return this.styleClass[countClass];
    },
    async loadBasicData() {
      try {
        this.getAlunos();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async getAlunos() {
      this.$loaderService.open("Carregando dados...");
      this.alunos = await this.$services.alunosService.getByGestao(
        parseInt(this.$route.params.gestaoDeAulaId, 10)
      );

      const response = await this.$services.relatorioAcompanhamentoAlunoService.syncAll(
        parseInt(this.$route.params.gestaoDeAulaId, 10)
      );

      this.alunos = this.alunos.map((aluno) => {
        return {
          ...aluno,
          relatorio: response.find((relatorio) => relatorio.aluno_id === aluno.aluno.id),
        };
      });

      this.alunos.forEach((aluno) => {
        this.campo_0_select_aluno[aluno.aluno.id] = JSON.parse(aluno.relatorio.campo_0_select);
        this.campo_1_select_aluno[aluno.aluno.id] = JSON.parse(aluno.relatorio.campo_1_select);
        this.campo_2_select_aluno[aluno.aluno.id] = JSON.parse(aluno.relatorio.campo_2_select);
        this.campo_3_select_aluno[aluno.aluno.id] = JSON.parse(aluno.relatorio.campo_3_select);
        this.campo_4_select_aluno[aluno.aluno.id] = JSON.parse(aluno.relatorio.campo_4_select);
        this.campo_0_texto_aluno[aluno.aluno.id] = aluno.relatorio.campo_0_texto;
        this.campo_1_texto_aluno[aluno.aluno.id] = aluno.relatorio.campo_1_texto;
        this.campo_2_texto_aluno[aluno.aluno.id] = aluno.relatorio.campo_2_texto;
        this.campo_3_texto_aluno[aluno.aluno.id] = aluno.relatorio.campo_3_texto;
        this.campo_4_texto_aluno[aluno.aluno.id] = aluno.relatorio.campo_4_texto;
      });
    },
    updateCampo(campoNumber, alunoId) {
      this.$toast.info("Salvando relatório...");
      switch (campoNumber) {
        case 0:
          this.salvarRelatorio(alunoId, this.campo_0_select_aluno[alunoId], "campo_0_select");
          break;
        case 1:
          this.salvarRelatorio(alunoId, this.campo_1_select_aluno[alunoId], "campo_1_select");
          break;
        case 2:
          this.salvarRelatorio(alunoId, this.campo_2_select_aluno[alunoId], "campo_2_select");
          break;
        case 3:
          this.salvarRelatorio(alunoId, this.campo_3_select_aluno[alunoId], "campo_3_select");
          break;
        case 4:
          this.salvarRelatorio(alunoId, this.campo_4_select_aluno[alunoId], "campo_4_select");
          break;

        default:
          break;
      }
    },

    updateTexto(campo) {
      this.$toast.info("Salvando relatório...");
      switch (campo) {
        case 0:
          this.salvarRelatorio(
            this.campo_0_texto_aluno.length - 1,
            this.campo_0_texto_aluno[this.campo_0_texto_aluno.length - 1],
            "campo_0_texto"
          );
          break;
        case 1:
          this.salvarRelatorio(
            this.campo_1_texto_aluno.length - 1,
            this.campo_1_texto_aluno[this.campo_1_texto_aluno.length - 1],
            "campo_1_texto"
          );
          break;
        case 2:
          this.salvarRelatorio(
            this.campo_2_texto_aluno.length - 1,
            this.campo_2_texto_aluno[this.campo_2_texto_aluno.length - 1],
            "campo_2_texto"
          );
          break;
        case 3:
          this.salvarRelatorio(
            this.campo_3_texto_aluno.length - 1,
            this.campo_3_texto_aluno[this.campo_3_texto_aluno.length - 1],
            "campo_3_texto"
          );
          break;
        case 4:
          this.salvarRelatorio(
            this.campo_4_texto_aluno.length - 1,
            this.campo_4_texto_aluno[this.campo_4_texto_aluno.length - 1],
            "campo_4_texto"
          );
          break;

        default:
          break;
      }
    },

    async salvarRelatorio(alunoId, dado, campo) {
      const response = await this.$services.relatorioAcompanhamentoAlunoService.salvar(
        parseInt(alunoId, 10),
        { campo, dado, gestaoId: this.$route.params.gestaoDeAulaId }
      );
    },
  },
};
</script>

<style></style>
