<template>
  <div>
    <v-navigation-drawer width="400" v-model="localDrawer" right temporary app>
      <v-list-item :class="localAula.color">
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{
            localAula.tipo_de_aula
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Data</v-list-item-title>
          <p class="font-weight-light text-justify">
            {{ localAula.data_formatada }}
            {{ localAula.horario ? " - " + localAula.horario.descricao : "" }}
          </p>
          <v-list-item-title class="mt-4">Saberes e conhecimentos</v-list-item-title>
          <p class="font-weight-light text-justify pt-4">
            {{ localAula.saberes_conhecimentos }}
          </p>

          <v-list-item-title class="mt-4">Estratégia Didática (Metodologia)</v-list-item-title>
          <p class="font-weight-light text-justify pt-4">
            {{ localAula.metodologia }}
          </p>

          <v-list-item-title class="mt-4">
            Objetivos de Aprendizagem e Desenvolvimento
          </v-list-item-title>
          <div v-for="bncc in localAula.sistema_bncc" :key="bncc.id">
            <p class="font-weight-light text-justify pt-4">
              {{ bncc.descricao }}
            </p>
            <p class="font-weight-light text-justify">
              <v-chip color="orange" dark class="break-text">
                {{ bncc.parent.descricao }}
              </v-chip>
            </p>
            <v-list-item-title class="mt-4">Campos de Experiência</v-list-item-title>
            <p class="font-weight-light text-justify pt-4">
              {{ bncc.parent.descricao }}
            </p>
            <v-divider></v-divider>
          </div>
          <v-list-item-title class="mt-4">Situação</v-list-item-title>
          <v-list-item-title>
            <v-chip label :color="localAula.color">
              {{ localAula.descricaoSituacao }}
            </v-chip>
          </v-list-item-title>
          <v-list-item-title class="mt-4">Motivo</v-list-item-title>
          <p class="font-weight-light">
            {{ localAula.motivo ? localAula.motivo : "Sem motivo." }}
          </p>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>
    <e-modal-duplicar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="localAula"
      :form="{ ...localAula }"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula>
    <e-modal-exportar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.exportar"
      :form="{ ...localAula }"
      @dialogChange="dialog.exportar = $event"
    >
    </e-modal-exportar-aula>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    aula: {
      type: Object,
      required: true,
    },
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    localDrawer() {
      this.$emit("input", this.localDrawer);
    },
  },
  computed: {
    localAula() {
      return this.aula;
    },
  },
  data() {
    return {
      localDrawer: this.value,
      dialog: {
        duplicar: false,
        exportar: false,
      },
      submittingForm: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.break-text {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
</style>
