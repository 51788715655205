import Axios from "@/plugins/Axios";
import store from "@/store";
import SubEtapa from "@/Models/SubEtapa";

export class RelatorioAcompanhamentoAlunoService {
  async syncAll(gestaoId) {
    const response = await Axios().get(`relatorioAcompanhamentoAlunos/syncAll/${gestaoId}`);
    return response.data;
  }

  async salvar(alunoId, dados) {
    const response = await Axios().post(`relatorioAcompanhamentoAlunos/salvar/${alunoId}`, dados);
    const respota = response;
    return respota;
  }

  async baixar(gestao) {
    const response = await Axios()
      .get(`relatorioAcompanhamentoAlunos/baixar/${gestao}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `relatorio_acompanhamento_aluno_${gestao}.pdf`;
        link.click();
      });
    return response;
  }
}
export default new RelatorioAcompanhamentoAlunoService();
