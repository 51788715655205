<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'usuarios' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Lista de Usuários COM/SEM bloqueios
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-sheet color="white" elevation="1" rounded>
          <v-lazy>
            <div class="text-start py-2 px-4 text-button">Seleção avançada</div>
          </v-lazy>
          <v-divider class="theme--light"></v-divider>
          <div class="py-2 px-4">
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form @submit.prevent.stop="handleSubmit(submitForm)" :disabled="submittingForm">
                <v-row>
                  <v-col cols="12" sm="8" xs="12">
                    <e-label>Escolha o(s) grupo</e-label>
                    <ValidationProvider name="regras/grupos" rules="required" v-slot="{ errors }">
                      <e-autocomplete
                        :items="roles"
                        :return-object="false"
                        :error-messages="errors"
                        v-model="form.roles"
                        label="Selecione uma opção"
                        item-text="name"
                        item-value="id"
                        dense
                        solo
                        multiple
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="4" xs="12">
                    <e-label>Escolha o(s) situaçao</e-label>
                    <ValidationProvider name="situação" rules="required" v-slot="{ errors }">
                      <e-autocomplete
                        :items="$constants.situacoesUsuarios"
                        :return-object="false"
                        :error-messages="errors"
                        v-model="form.situacao"
                        item-text="descricao"
                        item-value="descricao"
                        label="Selecione uma opção"
                        dense
                        solo
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-subheader
                    >* Todos os membros pertencentes ao grupo sofrerão o mesmo impacto. Por isso,
                    preste atenção antes de executar a ação.</v-subheader
                  >
                  <v-col>
                    <v-btn
                      color="primary"
                      :disabled="submittingForm"
                      :loading="submittingForm"
                      type="submit"
                      block
                      >Enviar Dados</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col>
        <template>
          <v-data-table
            v-model="selected"
            :headers="table.headers"
            :items="outrosUsuariosList"
            :single-select="singleSelect"
            hide-default-footer
            item-key="name"
            class="elevation-1"
          >
            <template v-slot:item.role_id="{ item }">
              <e-user-role :value="item" />
            </template>
            <template v-slot:item.situacao="{ item }">
              <e-user-situacao :value="item" />
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip v-if="!item.estaDeletado" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1"
                    color="blue-grey lighten-3"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => editarUsuario(item)"
                  >
                    <v-icon small>fa fa-edit </v-icon>
                  </v-btn>
                </template>
                <span>Editar usuario</span>
              </v-tooltip>
              <v-tooltip v-if="!item.estaDeletado" color="error" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="usuarioPrevilegiado"
                    x-small
                    class="ml-1"
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => deletarUsuario(item)"
                  >
                    <v-icon small>fa fa-trash </v-icon>
                  </v-btn>
                </template>
                <span>Apagar usuario</span>
              </v-tooltip>
              <v-tooltip v-if="item.estaDeletado" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="ml-1"
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => recuperarUsuario(item)"
                  >
                    <v-icon small>fa fa-sync </v-icon>
                  </v-btn>
                </template>
                <span>Recuperar usuario</span>
              </v-tooltip>
            </template>
          </v-data-table>

          <e-paginate
            :current_page="paginate.current_page"
            :last_page="paginate.last_page"
            :pageNumber="pageNumber"
            @changeActionResponse="carregarUsuarios"
            :search="search"
          ></e-paginate>
        </template>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Usuario from "../../Models/User";

export default {
  props: {},
  mounted() {
    this.carregandoUsuarios = true;
    this.$loaderService.open("Carregando Usuarios");
    this.carregarUsuarios(this.pageNumber);
    this.carregandoUsuarios = false;
    this.$loaderService.close();
    this.getRoles();
    this.verifyUseAuth();
  },
  data() {
    return {
      usuarioPrevilegiado: true,
      submittingForm: false,
      form: {
        situacao: null,
        roles: [],
      },
      roles: [],
      selected: [],
      singleSelect: false,
      usuarios: [],
      carregandoUsuarios: false,
      usuariosLoaded: false,
      usuariosList: [],
      outrosUsuariosList: [],
      dataTableFilter,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },

      table: {
        headers: [
          { text: "#", value: "id" },
          // { text: "Código", value: "codigo" },
          { text: "Nome", value: "name" },
          { text: "Login de acesso", value: "email" },
          { text: "Perfil", value: "role_id" },
          { text: "Situação", value: "situacao" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  watch: {
    carregandoUsuarios(value) {
      if (value && this.outrosUsuariosList.length === 0) {
        this.$loaderService.open("Carregando usuarios");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val.length > 1) {
        await this.carregarUsuarios(0, val);
      }
      if (!val) {
        await this.carregarUsuarios(0);
      }
    },
  },
  methods: {
    async verifyUseAuth() {
      try {
        const response = await this.$services.authService.getCurrentUser();
        this.usuarioPrevilegiado = response.root !== 1;
      } catch (error) {
        this.$handleError(error);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    async submitForm() {
      this.$toast.warn("Tentando atualizar os dados, aguarde!");
      this.submittingForm = true;
      try {
        await this.$services.rolesService.allUsers(this.form);
        this.$toast.success("Usuario(s) atualizado(s) com sucesso");
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.submittingForm = false;
      }
    },
    async getRoles() {
      try {
        const roles = await this.$services.rolesService.syncAll();
        this.roles = roles;
        return;
      } catch (error) {
        this.$handleError(error);
      }
    },
    addSelect(usuario) {
      if (!this.usuarioInseridoNoSelected(usuario)) {
        this.selected.push(usuario);
      }
    },
    editarUsuario(usuario) {
      this.$router.push({ name: "usuarios.edit", params: { usuario_id: usuario.id } });
      // return usuario;
    },
    async deletarUsuario(usuario) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar este(a) usuario(a)?",
        confirmationCode: usuario.id,
        confirmationMessage: `Por favor, digite <strong>${usuario.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando o(a) usuario(a)");
              try {
                await this.$services.usuariosService.deletarUsuario(usuario);
                this.$toast.success("Usuário(a) Deletado(a) com sucesso");
                await this.carregarUsuarios();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarUsuario(usuario) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar este(a) usuario(a)?",
        confirmationCode: usuario.id,
        confirmationMessage: `Por favor, digite <strong>${usuario.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando o(a) usuario(a)");
              try {
                await this.$services.usuariosService.recuperarUsuario(usuario);
                this.$toast.success("Usuario(a) recuperado(a) com sucesso");
                await this.carregarUsuarios();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async carregarUsuarios(pageNumber, query = null) {
      try {
        const response = await this.$services.usuariosService.syncAllPaginate(pageNumber, query);
        this.paginate = response.data;
        const usuarios = response.data.data.map((usuario) => new Usuario(usuario));
        this.outrosUsuariosList = usuarios;
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
